.sub_hero_section_container{
    height: 236px;
    width: 100vw;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10rem;
    overflow: hidden;
    justify-content: space-between;
    background-color: #1F4B43;
 
}

.sub_hero_text{
   text-align: left; 
}
/* mobile responsiveness */
@media (max-width:841px) {
    .sub_hero_section_container{    
        flex-direction: column;
        padding: 0 2rem;
        justify-content: center;
        gap: 10px;
    }

    sub_hero_text h1{
        font-size: 20px;
        text-align: center;
    }
}

sub_hero_text p{
    font-size: 15px;
    text-align: center;
}
