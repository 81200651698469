/* .prop_container {
    padding: 2rem 10rem;
    background-image: url("../../assets/dh_bg_image.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
} */
   
  .prop_image_section,
  .prop_text_section {
    flex-basis: 45%;
    margin: 0 2rem;
  }
  
  .prop_image_section img {
    width: 54rem;
    height: 250px;
    margin-top: 30px;   
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow: visible;
    object-fit:contain;
  }
  
  .prop_text_section {
    text-align: left;
    padding-left: 23px;
  
  }
  
  .prop_text_section div p {
    color: #1f4b43;    
    font-size: 30px;
    font-weight:lighter;
    padding-right: 10rem;
    margin: 0, 0 1rem, 0;
  }
  .prop_text_section div span { 
    color: #1f4b43;    
    font-size: 33px;
    font-weight: bolder;
  }
  
  
  @media (max-width: 841px) {
    .prop_container {
      padding: 2rem;
      height: max-content;
      flex-direction: column;
    }
  
    .prop_text_section {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  
    .prop_image_section,
    .prop_text_section {
      flex-basis: 100%;
    }
/*   
    .os_text_section {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    } */
  
    .prop_text_section div p {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      font-family: medium;
      padding-right: 0rem;
      margin: 0 0 1rem 0;
    
    }
  
    .prop_text_section div span {
      font-size: 28px;
    }  
  
    .prop_image_section img {
        width: 430px;
        height: 250px;
        margin-top: 30px;   
        border-radius: 10px;
        display: flex;
        align-items: center;
        overflow: visible;
        object-fit:contain;
      }
      
    .prop_image_section {
      width: 80vw;
    }
  }