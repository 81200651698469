* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .image_div img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: 6px;
  }
  figure {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  figcaption {
    width: 190%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .title_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .title_div p {
    font-size: 16px;
    font-weight: bold;
  }
  
  article {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .button_div {
    display: flex;
    padding: 10px 0;
    flex-wrap: nowrap;
  }
  
  button {
    margin: 4px;
    border: none;
    font-size: 8px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 5px;
    white-space: nowrap;
    display: inline-block;
    color: #011948;
    background-color: #dedede;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  .price {
    font-size: 12px;
    margin-left: 4px;
    margin-top: 4px;
    text-align: start;
    color: #909090;
  }
  
  @media (max-width: 841px) {
    figcaption {
      width: 100%;
      padding: 12px ;
    }
    .title_div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .title_div p {
      font-weight: bold;
      font-size: 16px;
    }
  }