     * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
    .discover_container {
      background: url("../../assets/development.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 5rem 0;
    }
    
    .discover_div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .discover_div h2 {
      color: white;
      padding: 12px;
    }