* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    background-color: rgba(187, 219, 243, 0.2);
  }
  
  .containers {
    width: 80%;  
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  
  .left_section {
    width: 40%;
  }
  
  .left_section > div > h1 {
    width: 40%;
    font-size: 2rem;
    color: #1f4b43;
    text-align: left;
    padding: 4px 0;
  }
  
  .body_text {
    text-align: left;
    padding: 1rem 0;
  }
  
  .right_section {
    width: 40%;
  }
  
  .image_div img {
    width: 90%;
    height: 75%;
    object-fit: cover;
  }
  
  .app_store_div {
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
    background-color: #1f4b43;
    border-radius: 10px;
    padding: 10px 8px;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  
  .download {
    color: #fff;
    font-size: 10px;
    letter-spacing: 1px;
  }
  .app_store {
    color: #fff;
    font-size: 14px;
  }
  small {
    color: #a4a4a4;
  }
  
  .download_wrapper {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  
  @media (max-width: 841px) {
    .containers {
      width: 80%;
      display: flex;
      flex-direction: column-reverse;
    }
  
    .left_section,
    .right_section {
      width: 100%;
    }
  
    .image_div img {
      width: 100%;
      object-fit: cover;
     
    }
    .app_store_div {
      width: 50%;
    }
    .left_section div h1 {
      width: 100%;
      font-size: 2rem;
      text-align: center; 
      

    }
  }