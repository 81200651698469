.header_container{   
    height: 52px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10rem;
}
.header_cta_section{
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.header_cta_section ul li a{
text-decoration: none;
font-style: normal;
font-weight: normal;
color: #1f4b43;
font-size: 14px;



}

.header_cta_section ul li{
    list-style-type: none;
    text-decoration: none; 
       
    }

     .mobile_logo{
        display: none;
     }

     .menu{
        display: none;
     }

    /*mobile Responsiveness*/
@media (max-width: 768px){
    .header_container{
        width: 100vw;
        justify-content: space-between;
        padding: 0; /*Padding for smaller screen*/
    }

    .desktop_logo{
        display: none;
    }

    .mobile_logo{
        display: block;
    }

    .menu{
        display: block;
        padding-left:10px;
    }

    .header_cta_section{
        padding-right:10px;
        flex-direction: row;
        gap:10px;
    }

    .header_cta_section ul{
        display: none;
    }

    .header_cta_section ul li a {
        font-size: 12px;
    }

}