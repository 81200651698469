
.hero_section_container{
    height: 92vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero_section_content{
    width: 45%; 
    height: max-content;
    margin-top: 5rem;  
    
}

.hero_section_content p{
    font-size: 45px;
    color: #1f4b43;
    text-align: center;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;

}

.hero_section_content span{
    font-size: 18px;
    color:#35384b; 
    padding: 0 1rem;
    align-items: center;  

}

.btn_container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 3rem;
}
.btn_container .btn, .btn_container .outline{
    width: 32%;

}


@media (max-width: 841px) {   
.hero_section_container{
    height: 92vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero_section_content{
    width: 100%;
    padding: 0, 1rem;     
    margin-top: 0rem;  
    
}

.hero_section_content p{
    font-size: 40px;
}

.hero_section_content span{  
    padding: 0 1rem;
}

.btn_container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 3rem;
}

.btn_container .btn, .btn_container .outline{
    width: 45%;

}

}



