* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .find_home_container {
    width: 100%;
  }
  
  .parent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popular {
    padding: 2rem 0;
  }
  
  .find_home_wrapper {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
  }
  
  .find_home_wrapper > div > h1 {
    color: #1f4b43;
  }
  
  .boxes_parent_container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    padding: 1rem 0;
    margin: 0 auto;
  }
  
  .second_boxes_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .third_boxes_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  @media (max-width: 841px) {
    .boxes_parent_container {
      width: 100%;
      margin-left: -0.9rem;
    }
  }